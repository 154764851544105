<template>
  <b-container fluid>
    <div class="loading" v-if="loading">Loading&#8230;</div>
    <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
        <h4>Stock valorizado</h4>
      </b-col>
    </div>

    <b-row class="stats text-center">
      <b-col cols="lg-12 text-center">
        <div class="stat">
          <div class="number">{{formatoDinamicoMoneda(valuedStockNumber) }}</div>
          <div class="desc">VALOR TOTAL DEL STOCK</div>
        </div>
      </b-col>
    </b-row> 
      
      <div class="card bg-light rounded-sm p-3 my-2 border">
        <div class="row d-flex justify-content-between">
          <div class="col col-md-2">
            <b-form-group label="Categoria" label-class="text-left">
              <select v-model="category_id" class="form-control btn-light load-data" style="width: 208px;">
                <option :value="null">- Categoría -</option>
                <option v-for="category in categories" :key="category.id" :value="category.id" v-html="category.name"></option>
              </select>
            </b-form-group>
          </div>
          <div class="col col-md-2">
            <b-form-group label="Lista de precios" label-class="text-left">
              <select class="form-control btn-light load-data" v-model="pricelist_id" style="width: 208px;">
                <option :value="0">Costo</option >
                  <option v-for="priceList in priceLists" :key="priceList.id" :value="priceList.id">{{ priceList.name }}</option>
                </select>
            </b-form-group>
          </div>
          <div class="col col-md-2 d-flex justify-content-left">
            <b-form-group label="Exportar" label-class="text-left">
              <input type="button" @click="exportCSV()" class="btn btn-md btn-outline-primary mr-2" value="Exportar CSV">
              <input type="button" @click="exportPDF()" class="btn btn-md btn-outline-primary" value="Exportar PDF">
            </b-form-group>
            </div>
        </div>
      </div>

      <b-row>
        <b-col cols="md-12">
              <vdtnet-table
                :fields="fields"
                :opts="opts"
                :selectCheckbox="0"
                ref="table"
              />
        </b-col>
      </b-row>    
      
  </b-container>

</template>

<script>
import VdtnetTable from "vue-datatables-net";
import formatMoneyMixin from "../../mixins/formatMoneyMixin";
import exportsMixin from "../../mixins/exportsMixin";
import { mapState } from 'vuex';

export default {
  components: {
    VdtnetTable,
  },
  mixins: [formatMoneyMixin,exportsMixin],

  data: function() {
    return {
      loading: false,
      valuedStockNumber: 0,
      opts: {
        language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
          },
        ajax: {
          url: process.env.VUE_APP_BASE_URL+"/api/reports/stockvalued",
          type: "GET",
          data: (d) => {
            d.pricelist_id = this.pricelist_id,
            d.category_id = this.category_id
            return d
          },
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        },
        processing: true,
        pageLength: 15,
        searching: true,
        searchDelay: 1500,
        destroy: true,
        //order: [[0, 'desc']],
        //ordering: true,
        lengthChange: true,
        serverSide: true,
        fixedHeader: true,
      },
      fields: {
        name: { label: "Nombre" },
        provname: { label: "Proveedor" },
        price: { label: "Precio", render: this.formatoDinamicoMoneda },
        stock: { label: "Cantidad" },
        total: { label: "Total", render: this.formatoDinamicoMoneda },
      },
      pricelist_id: 0,
      priceLists: [],
      category_id:null,

    };
  },
  computed:{
    ...mapState('categories',{
          categories: state => state.categories,
        }),
    filters() {
      return {
        pricelist_id: this.pricelist_id,
        category_id : this.category_id,
      };
    }
  },
  created() {
    this.$store.dispatch('categories/getCategories')
  },
  mounted() {
    this.getValuedStockTotal();
    this.getPriceLists();
  },
  methods: {
    reload() {
      this.refreshTable()
      this.getValuedStockTotal();
    },
    refreshTable() {
        if (this.$refs.table !== undefined)
          this.$refs.table.reload();
      },
    getValuedStockTotal() {
      var vm = this;
      vm.isLoading = true;
      this.$http.get(process.env.VUE_APP_BASE_URL+"/api/reports/stockvalued_number", {
         params: this.filters ,
         headers: { Authorization: "Bearer " + localStorage.getItem("token") }
        }).then((response) => {
          vm.valuedStockNumber = response.data.data;
          console.log(vm.valuedStockNumber);
          vm.isLoading = false;
      });
    },
    getPriceLists() {
        var vm = this;
        vm.isLoading = true
        this.$http.get(process.env.VUE_APP_BASE_URL+"/api/priceslist/list").then((response) => {
          vm.priceLists = response.data.data;
          vm.isLoading = false
        });
      },
    exportCSV() {
      this.$http.post(process.env.VUE_APP_BASE_URL+"/api/reports/CSVStockValued", this.filters)
      .then((response) => {
      });
    },
    exportPDF() {
      this.$http.post(process.env.VUE_APP_BASE_URL+"/api/reports/PDFStockValued", this.filters)
      .then((response) => {
      });
    },
  },
  watch: {
    filters() {
      this.reload();
    },
  }
};
</script>
