<template>
  <b-container fluid>
    <div class="loading" v-if="loading">Loading&#8230;</div>
    <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
        <h4>IVA Ventas</h4>
      </b-col>
    </div>
    <b-row class="statsreport mt-2">
      <b-col cols="lg-2 md-2 sm-12 xs-12 border-r-l">
        <div class="stat">
          <div class="number small">{{ formatoDinamicoMoneda(subtotal) }}</div>
          <div class="desc">Total neto gravado </div>
        </div>
      </b-col>
      <b-col cols="lg-1 md-1 sm-12 xs-12 border-r-l">
        <div class="stat">
          <div class="number small"><span>{{ formatoDinamicoMoneda(ewamount) }}</span></div>
          <div class="desc">Retencion de ganancias</div>
        </div>
      </b-col>
      <b-col cols="lg-1 md-1 sm-12 xs-12 border-r-l">
        <div class="stat">
          <div class="number small"><span>{{ formatoDinamicoMoneda(ewivaamount) }}</span></div>
          <div class="desc">Retencion de IVA</div>
        </div>
      </b-col>
      <b-col id="tax25" cols="lg-1 md-1 sm-1 xs-12 border-r-l">
        <div class="stat">
          <div class="number small"><span class="tax25">{{ formatoDinamicoMoneda(tax25) }}</span></div>
          <div class="desc">IVA 2,5%</div>
        </div>
      </b-col>
      <b-col id="tax50" cols="lg-1 md-1 sm-1 xs-12 border-r-l">
        <div class="stat">
          <div class="number small"><span class="tax50">{{ formatoDinamicoMoneda(tax50) }}</span></div>
          <div class="desc">IVA 5%</div>
        </div>
      </b-col>
      <b-col id="tax105" cols="lg-1 md-1 sm-1 xs-12 border-r-l">
        <div class="stat">
          <div class="number small"><span class="tax105">{{ formatoDinamicoMoneda(tax105) }}</span></div>
          <div class="desc">IVA 10,5%</div>
        </div>
      </b-col>
      <b-col id="tax210" cols="lg-1 md-1 sm-1 xs-12 border-r-l">
        <div class="stat">
          <div class="number small"><span class="tax210">{{ formatoDinamicoMoneda(tax210) }}</span></div>
          <div class="desc">IVA 21%</div>
        </div>
      </b-col>
      <b-col id="tax270" cols="lg-1 md-1 sm-1 xs-12 border-r-l">
        <div class="stat">
          <div class="number small"><span class="tax270">{{ formatoDinamicoMoneda(tax270) }}</span></div>
          <div class="desc">IVA 27%</div>
        </div>
      </b-col>
      <b-col cols="lg-1 md-1 sm-12 xs-12 border-r-l">
        <div class="stat">
          <div class="number small">{{ formatoDinamicoMoneda(tax) }}</div>
          <div class="desc">Total IVA</div>
        </div>
      </b-col>
      <b-col cols="lg-2 md-2 sm-12 xs-12 border-r-l">
        <div class="stat">
          <div class="number small">{{ formatoDinamicoMoneda(total) }}</div>
          <div class="desc">Total VENTAS </div>
        </div>
      </b-col>
    </b-row>

    <div class="p-3 bg-light rounded-sm my-2 border">
      <div class="row d-flex justify-content-between">
        <div class="col col-md-2">
          <b-form-group label="Tipo" label-class="text-left">
            <select class="form-control btn-light load-data" id="invoiceType" name="invoiceType" v-model="invoiceType">
              <option selected="selected" value="">Todos los tipos</option>
              <option value="FC">FC</option>
              <option value="NC">NC</option>
              <option value="ND">ND</option>
            </select> 
          </b-form-group>
        </div>
        <div class="col col-md-2">
          <b-form-group label="Punto de venta" label-class="text-left">
            <select size="1" v-model="selectedPos" class="form-control btn-light load-data" id="invoiceType" style="width: 208px;" placeholder="Punto de venta">
              <option v-for="pos in posList" :key="pos.id" :value="pos.id">{{pos.branchoffice.name}} - {{pos.number}}</option>
            </select>
          </b-form-group>
        </div>
        <div class="col col-md-2 d-flex justify-content-left">
          <b-form-group label="Fecha" label-class="text-left">
            <Daterange :opendir="'left'" @datepicked="dateCreated" name="daterange"/>
          </b-form-group>
        </div>
        <div class="col col-md-2 d-flex justify-content-left">
          <b-form-group label="Exportar" label-class="text-left">
            <input type="button" @click="exportCSV()" class="btn btn-md btn-outline-primary mr-2" value="Exportar CSV">
            <input type="button" @click="exportPDF()" class="btn btn-md btn-outline-primary" value="Exportar PDF">
          </b-form-group>
        </div>
      </div>
    </div>

    <b-row>
      <div class="col-md-12">
        <vdtnet-table
          :fields="fields"
          :opts="opts"
          :selectCheckbox="0"
          ref="table"
        />
      </div>
    </b-row>
  </b-container>
</template>
<script>
import VdtnetTable from "vue-datatables-net";
import Daterange from "../global/Daterange-component.vue";
import formatMoneyMixin from "../../mixins/formatMoneyMixin";
import formatDateMixin from "../../mixins/formatDateMixin";
import exportsMixin from "../../mixins/exportsMixin";

export default{
  components: {
    VdtnetTable,
    Daterange,
  },
  mixins: [formatMoneyMixin,formatDateMixin,exportsMixin],
  data: function() {
    return {
      loading: false,
      totalSales:0,
      totalCash:0,
      totalCC:0,
      totalTpCheks:0,
      totalCards:0,
      posList:[],
      selectedPos:[],
      invoiceType: "",
      startDate: null,
      endDate: null,
      opts: {
        language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
          },
        ajax: {
          url: process.env.VUE_APP_BASE_URL+"/api/reports/ivasales",
          type: "GET",
          data: (d) => {
            d.invoiceType= this.invoiceType,
            d.pos= this.selectedPos,
            d.daterange= this.daterange
            return d
          },
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        },
          
        processing: true,
        pageLength: 15,
        searching: true,
        searchDelay: 1500,
        destroy: true,
        order: [[0, 'desc']],
        ordering: true,
        lengthChange: true,
        serverSide: true,
        fixedHeader: true,
      },
      subtotal: null,
      ewamount: null,
      ewivaamount: null,
      tax25: null,
      tax50: null,
      tax105: null,
      tax210: null,
      tax270: null,
      tax: null,
      total: null,
    }
  },
  mounted() {
    this.loadSalepoints();
  },
  computed:{
    fields() { return  {
        dateHour: { label: "Fecha", searchable: true,
          render: (data) => this.formatoFechaHora(data),
        },
        dateHour: { label: "fecha" },
        receipt: { label: "Comprobante" },
        client_name: { label: "Cliente" },
        client_cuit: { label: "Cuit" },
        nettaxed: { label: "Neto Gravado",
          searchable: true,
          render: this.formatoDinamicoMoneda,
        },
        iva25: { label: "2,5%",
          render: this.formatoDinamicoMoneda,
        },
        iva50: { label: "5%",
          render: this.formatoDinamicoMoneda,
        },
        iva105: { label: "10,5%",
          render: this.formatoDinamicoMoneda,
        },
        iva210: { label: "21%",
          render: this.formatoDinamicoMoneda,
        },
        iva270: { label: "27%",
          render: this.formatoDinamicoMoneda,
        },
        total: { label: "total",
          render: this.formatoDinamicoMoneda,
         },
      };
    },
    daterange() {
      if (this.startDate === null || this.endDate === null) {
        return null;
      }
      const startDate = this.formatoFecha2Daterange(this.startDate);
      const endDate = this.formatoFecha2Daterange(this.endDate);
      return `${startDate} - ${endDate}`;
    },
    filters() {
      return {
          invoiceType: this.invoiceType,
          pos: this.selectedPos,
          daterange: this.daterange,
        };
    }
  },
  methods: {
    refreshTable() {
        if (this.$refs.table !== undefined)
          this.$refs.table.reload();
      },
    dateCreated(date) {
      this.startDate = date.startDate;
      this.endDate = date.endDate;
    },
    loadSalepoints() {
          let vm = this;
          axios.get(process.env.VUE_APP_BASE_URL+'/api/configs/pos/list')
            .then((response) => {
                if (response.data.success) {
                    vm.posList = response.data.data;
                } else {
                    
                }
            }).catch((error) => {
                console.log('error: '+error);
            })
        },
    getTotalIvaSales(d, cb) {
    var vm = this;
    this.$http
      .get(process.env.VUE_APP_BASE_URL+"/api/reports/ivasales", {
         params: this.filters ,
         headers: { Authorization: "Bearer " + localStorage.getItem("token") }
        })
      .then((response) => {
        vm.ivaSales= response.data.data;
        cb(response.data);
      });
    },   
    getTotals() {
      var vm = this;
      vm.loading = true;
      this.$http.get(process.env.VUE_APP_BASE_URL+"/api/reports/statsivasales", {params: this.filters}).then((response) => {
        vm.subtotal = response.data.data.subtotal;
        vm.ewamount = response.data.data.ewamount;
        vm.ewivaamount = response.data.data.ewivaamount;
        vm.tax25 = response.data.data.tax25;
        vm.tax50 = response.data.data.tax50;
        vm.tax105 = response.data.data.tax105;
        vm.tax210 = response.data.data.tax210;
        vm.tax270 = response.data.data.tax270;
        vm.tax = response.data.data.tax;
        vm.total = response.data.data.total;
        vm.loading = false;
      }); 
    },
    exportCSV() {
      this.$http.post(process.env.VUE_APP_BASE_URL+"/api/reports/CSVsaleIVA", this.filters)
      .then((response) =>{
      });
    },
    exportPDF() {
      this.$http.post(process.env.VUE_APP_BASE_URL+"/api/reports/PDFsaleIVA", this.filters)
      .then((response) =>{
      });
    },
  },
  watch: {
    filters() {
      this.refreshTable();
      this.getTotals();
    }
  }
}
</script>
<style scoped>
  .stats .stat {
    height: 15.5rem;
  }
</style>