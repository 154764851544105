<template>
  <b-container>
    <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
        <h4>Factura de Compra</h4>
      </b-col>
    </div>
    <div class="bg-light my-4 p-4 rounded-lg">
      <b-row class="text-center">
        <b-col>
          <h2>Ingresar Datos Factura de Compra</h2>
        </b-col>
      </b-row>
      <hr>
      <!-- --------------------- CABECERA ---------------------- -->
      <b-row>
        <b-col>
          <receipt-header
          @headerchanged="headerUpdated"
          :show-pricelist="showPricelist"
          :show-prices="showPrices"
          :show-choosedate="showChoosedate"
          :show-pos="showPos"
          :numbers="numbers"
          :salespoints="salespoints"
          :invoicem="invoicem"
          :pos_default="pos_default"
          :config="config"

          :pricelist_default="pricelist_default"
          :client_default="client_default"
          :company_typetax="company_typetax"
          />
        </b-col>
      </b-row>
      <br>
      <!-- --------------------- NOTA CLIENT ---------------------- -->
      <b-row>
        <b-col v-if="payload.header.loadproducts">
              <receipt-products
              :config="config"
              :show-taxes="showTaxes"
              :show-prices="showPrices"
              :show-barcodes="showBarcodes"
              @productschanged="productsUpdated"
              @sendProduct="sendProduct"
              :product_default="client_default"
              :disciva="disciva"
              :allow_sale_without_stock="allow_sale_without_stock"
              :prexistprd="prexistprd"
              />
        </b-col>
      </b-row>
      <div v-bind:class="[{'col-md-12 visible-md visible-lg': !payload.header.loadproducts}, {'col-md-12 visible-md visible-lg': payload.header.loadproducts}]">
        <div>
          <h2>Notas de la compra</h2>
          <textarea v-model="payload.saleNote" name="note" class="form-control" rows="3"></textarea>
        </div>
      </div>
      <!-- --------------------- TOTALES ---------------------- -->
      <hr>
      <b-row >
        <b-col>
            <div v-if="showTotals">
                <receipt-totals :totals="payload.totals" @totalschanged="totalsUpdated"/>
            </div>
        </b-col>
      </b-row>
      <!-- --------------------- BOTONES FINAL ---------------------- -->
      <b-row class="my-4 text-right" v-if="showPrices || !showTotals">
        <b-col>
          <b-button variant="orange" size="lg" v-if="showPaymentmethods && payload.totals.total>0 && totalUpperToSixDigit" @click="showModal = true">Pagar $ {{payload.totals.total}}</b-button>
          <b-button variant="orange" size="lg" v-if="hasProducts && !showPaymentmethods" @click="sendReceipt" >Finalizar</b-button>
        </b-col>          
      </b-row>
        <pay-order-payment-methods
        :disciva="disciva"
        :fundDefault="config.fund_default"
        :header="payload.header"
        @paymentmethodschanged="paymentMethodsUpdated"
        @paymentmethodsclose="paymentMethodsClose"
        v-bind:payment-methods="payment_methods"
        v-bind:total="payload.total"
        v-if="showModal"
        @close="showModal = false"
        :limited="false" />
    </div>
  </b-container>
</template>

<script>
import receiptProducts from './Receipt-products.vue';
import receiptHeader from './Receipt-header.vue';
import receiptTotals from './Receipt-totals.vue';
import payOrderPaymentMethods from '../../../paymentmethods/Pay-order-payment-methods.vue';

  export default {
    props: [
          'config','branchofficeData',
          'client_default', 'product_default', 'pricelist_default',
          'company_typetax', 'payment_methods', 'pos_default', 'note_default',
          'showPrices', 'showBarcodes', 'showPaymentmethods', 'debug',
          'showPos', 'showPricelist', 'showTaxes', 'showChoosedate', 'prexistprd',
          'numbers', 'salespoints', 'invoicem', 'allow_sale_without_stock', 'endpointurl','showTotals'
    ],
    data: function() {
      return {
        showModal: false,
        disciva: false,
        products: [],
        payload: {
          header: {},
          items: [],
          totals: {
            gross: 0,
            taxes: {
              two: 0,
              five: 0,
              ten: 0,
              twentyOne: 0,
              twentySeven: 0,
              lofty: 0,
              internalTax: 0,
              perceptionTax: 0,
              iibb: 0,
              otherTax: 0
            },
            total: 0,
            discount: 0,
          },
          saleNote: this.note_default,
        },
        modalProduct: false,
      }
    },
    computed: {
      showPayButton(){
        return this.showPaymentmethods && this.payload.totals.total>0 && 
          this.totalUpperToSixDigit && this.headerState;
      },
      headerState(){
        var number = this.payload.header.number;
        var pos = this.payload.header.pos;
        return number!= null && !isNaN(number) && number.length>0 &&
          pos!= null && !isNaN(pos) && pos.length>0 &&
          this.payload.header.provider_id!=null && this.payload.header.letter!=null
      },
      paymentMethods(){
        return this.payment_methods 
      },
      hasProducts(){
        return (this.payload.items.length>0);
      },
      totalUpperToSixDigit() {
        if (this.payload.total >= 9999999999999.99) {
          alert('Se pasó el límite de $9.999.999.999.999,99, por favor ingrese menos productos.');
          return false;
        } else {
          return true;
        }
      }
    },
    methods: {
      calcprod : function() {
        var app = this;
        for (var i = 0; i < app.products.length; i++) {
          if (app.products[i].includetax==1) {
            app.products[i].gross = app.products[i].price / (1+(app.products[i].taxtype/100));
          } else {
            app.products[i].gross = app.products[i].price;
          }
          app.products[i].tax = app.products[i].gross * app.products[i].taxtype/100;
        }
      },
      sendReceipt: function() {
        var app=this;
        for (var i = 0; i < app.payload.items.length; i++) {
          delete app.payload.items[i].code;
          delete app.payload.items[i].barcode;
          delete app.payload.items[i].stock;
        }
        if (app.debug) {console.log(app.payload);}
        this.$http.post(app.endpointurl, app.payload)
        .then(function (response) {
          if (response.data.success) {
           alert(response.data.data.responseText);
           if (!app.debug) {window.location.href = window.location.pathname;}
         } else {
           alert(response.data.message);
         }
        })
        .catch(function (error) {
          console.log(error.data);
          alert(error.data.message);
        });
      },
      headerUpdated: function(obj) {
        var app = this;
        app.payload.header = obj;
        if (app.payload.header.letter=="A" || app.payload.header.letter=="M") {
          app.disciva=true;
        } else {
          app.disciva=false;
        }
        app.calcprod();
      },
      paymentMethodsUpdated: function(obj) {
        var vm = this;
        vm.payload.paymentMethods = obj;
        console.log(vm.payload);
      },
      paymentMethodsClose: function(obj) {
        this.paymentMethodsUpdated(obj);
        this.productsUpdated(this.products);
        this.sendReceipt();
      },
      productsUpdated: function(obj) {
        this.payload.items = obj;
        this.products = obj;
        this.calcprod();
      },
      totalsUpdated: function(obj) {
        var app = this;
        app.payload.totals = obj
        app.payload.total = obj.total
        app.payload.subtotal = obj.gross
        app.payload.discount = obj.discount
      },
      sendProduct(product) {
        this.products.push(product);
        this.productsUpdated(this.products);
      }
    },
    components: {
      receiptProducts,
      receiptHeader,
      receiptTotals,
      payOrderPaymentMethods
    }
  }
</script>

<style lang="css">
  

  .note-client {
    margin-top: 15px;
  }

  .activate-payment {
    margin-top:8px;
    text-align: right;
  }
</style>
