<template>
  <b-container fluid>
    <div class="loading" v-if="loading">Loading&#8230;</div>
    <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
        <h4>Ranking de Ventas por producto</h4>
      </b-col>
    </div>
    <div class="p-3 bg-light rounded-sm my-2 border">
      <div class="row d-flex justify-content-between">
        <div class="col col-md-2">
          <b-form-group label="Codigo Interno" label-class="text-left">
            <input placeholder="Buscar codigo interno..." v-model="qcode" class="form-control input-sm">
          </b-form-group>
        </div>
        <div class="col col-md-2">
          <b-form-group label="Categoria" label-class="text-left">
            <select v-model="category_id" class="form-control btn-light load-data" style="width: 208px;">
              <option :value="null">- Categoría -</option>
              <option v-for="category in categories" :key="category.id" :value="category.id" v-html="category.name"></option>
            </select>
          </b-form-group>
        </div>
        <div class="col col-md-2 d-flex justify-content-left">
          <b-form-group label="Fecha" label-class="text-left">
            <Daterange :opendir="'left'" :filtered="true" @datepicked="dateCreated"/>
          </b-form-group>
        </div>
        <div class="col col-md-2 d-flex justify-content-left">
          <b-form-group label="Exportar" label-class="text-left">
            <input type="button" @click="exportCSV()" class="btn btn-md btn-outline-primary mr-2" value="Exportar CSV">
            <input type="button" @click="exportPDF()" class="btn btn-md btn-outline-primary" value="Exportar PDF">
          </b-form-group>
        </div>
      </div>
    </div>

    <b-row>
      <b-col cols="md-12 sm-12">
        <vdtnet-table
          :fields="fields"
          :opts="opts"
          :selectCheckbox="0"
          ref="table"
        />
      </b-col>
    </b-row>
    
  </b-container>
</template>

<script>
import VdtnetTable from "vue-datatables-net";
import Daterange from "../global/Daterange-component.vue";
import { mapState } from 'vuex';
import formatMoneyMixin from "../../mixins/formatMoneyMixin";
import formatDateMixin from "../../mixins/formatDateMixin";
import exportsMixin from "../../mixins/exportsMixin";

export default {
  components: {
    VdtnetTable,
    Daterange,
  },
  mixins: [formatMoneyMixin,formatDateMixin,exportsMixin],
  data: function() {
    return {
      loading: false,
      category_id:null,
      qcode: null,
      startDate:null,
      endDate:null,
      opts: {
        language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
          },
        ajax: {
          url: process.env.VUE_APP_BASE_URL+"/api/reports/rankingProduct",
          type: "GET",
          data: (d) => {
            d.category_id = this.category_id,
            d.daterange = this.daterange,
            d.qcode = this.qcode,
            d.filters= this.filters
            return d
          },
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        },
        processing: true,
        pageLength: 15,
        searching: true,
        searchDelay: 1500,
        destroy: true,
        order: [[2, 'desc']],
        ordering: true,
        lengthChange: true,
        serverSide: true,
        fixedHeader: true,
      },
      fields: {
        name: { label: "Producto" },
        code: { label: "Cod. Int" },
        quantity: { label: "Cantidad" },
        total: { label: "Total",
          searchable: true,
          render: this.formatoDinamicoMoneda,
        },
      },
    };
  },
    computed:{
      ...mapState('categories',{
          categories: state => state.categories,
        }),
      daterange() {
          if (this.startDate === null || this.endDate === null) {
              return null;
          }
          const startDate = this.formatoFecha2Daterange(this.startDate);
          const endDate = this.formatoFecha2Daterange(this.endDate);
          return `${startDate} - ${endDate}`;
      },
      filters(){
        return {
          category_id : this.category_id,
          daterange : this.daterange,
          qcode:'',
        }
      },
    },
  created () {
      this.$store.dispatch('categories/getCategories')
   },
    mounted() {
      this.setDefaultDateRange();
    },
  methods: {
    refreshTable() {  
      var vm = this;
      if (vm.$refs.table !== undefined) vm.$refs.table.reload();
    },
    dateCreated(date) {
      this.startDate = date.startDate;
      this.endDate = date.endDate;
      this.refreshTable();
    },
    exportCSV() {
      this.$http.post(process.env.VUE_APP_BASE_URL+"/api/reports/CSVRankingProductoReport", this.filters)
      .then((response) => {
      });
    },
    exportPDF() {
      this.$http.post(process.env.VUE_APP_BASE_URL+"/api/reports/PDFRankingProductoReport", this.filters)
      .then((response) => {
      });
    },
    setDefaultDateRange() {
        const today = new Date();
        const startDate = new Date();
        startDate.setDate(today.getDate() - 30);

        this.startDate = this.formatDate(startDate);
        this.endDate = this.formatDate(today);
    },
    formatDate(date) {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${day}/${month}/${year}`;
    },
  },
  watch: {
    category_id() {
      this.refreshTable();
    },
    daterange() {
      this.refreshTable();
    },
    qcode(){
      this.refreshTable();
    },
  },

};
</script>