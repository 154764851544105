<template>
  <b-container fluid>
    <div class="loading" v-if="loading">Loading&#8230;</div>
    <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
        <h4>Vision General de Ventas</h4>
      </b-col>
    </div>
    <b-row class="statsreport">
      <b-col cols="lg-3 md-3 sm-12 xs-12">
        <div class="stat">
          <div class="number">{{ formatoDinamicoMoneda(totalSales) }}</div>
          <div class="desc">Total en ventas</div>
        </div>
      </b-col>
      <b-col cols="lg-2 md-2 sm-12 xs-12 border-r-l">
        <div class="stat">
          <div class="number">{{ formatoDinamicoMoneda(totalCash) }}</div>
          <div class="desc">Efectivo</div>
        </div>
      </b-col>
      <b-col cols="lg-2 md-2 sm-12 xs-12 border-r-l">
        <div class="stat">
          <div class="number ">{{ formatoDinamicoMoneda(totalCC) }}</div>
          <div class="desc">Cuenta corriente</div>
        </div>
      </b-col>
      <b-col cols="lg-2 md-2 sm-12 xs-12 border-r-l">
        <div class="stat">
          <div class="number">{{ formatoDinamicoMoneda(totalTpChecks) }}</div>
          <div class="desc">Cheques de terceros</div>
        </div>
      </b-col>
      <b-col cols="lg-3 md-3 sm-12 xs-12 border-r-l">
        <div class="stat">
          <div class="number ">{{ formatoDinamicoMoneda(totalCards) }}</div>
          <div class="desc">Tarjetas</div>
        </div>
      </b-col>
    </b-row>

    <div class="p-3 bg-light rounded-sm my-2 border">
        <div class="row d-flex justify-content-between">
          <div class="col col-md-2">
            <b-form-group label="Tipo" label-class="text-left">
              <select class="form-control btn-light load-data" style="width: 208px;" v-model="invoiceType">
                <option selected="selected" value="">Todos los tipos</option>
                <option value="FC">FC</option>
                <option value="NC">NC</option>
                <option value="ND">ND</option>
              </select> 
            </b-form-group>
          </div>
          <div class="col col-md-2">
            <b-form-group label="Punto de venta" label-class="text-left">
              <select class="form-control btn-light load-data"  v-model="invoicePos">
                <option selected="selected" value="">Todos los puntos de venta</option>
                <option v-for= "pos in salespoints" :key= "pos.id" :value="pos.id">{{pos.number}} - {{pos.type}} - {{pos.branchoffice.name}}</option>
              </select> 
            </b-form-group>
          </div>
          <!--<div class="form-group">
          <div class="col col-md-2">
                <select class="form-control btn-light load-data" v-model="invoiceMethodPaid">
                <option selected="selected" value="">Todos los metodos de pagos</option>              
                <option value='cash' v-if="paymentsMethods.efectivo == 1">Efectivo</option>
                <option value='cc' v-if="paymentsMethods.cuenta_corriente == 1">Cuenta Corriente</option>
                <option value='check' v-if="paymentsMethods.cheques == 1">Cheques</option>
                <option value='card' v-if="paymentsMethods.tarjetas == 1">Tarjetas</option>
              </select>  
            </div>
          </div>-->
          <div class="col col-md-2 d-flex justify-content-left">
            <b-form-group label="Fecha" label-class="text-left">
              <Daterange :opendir="'left'" :filtered="true" @datepicked="dateCreated"/>
            </b-form-group>
          </div>
          <div class="col col-md-2 d-flex justify-content-left">
            <b-form-group label="Exportar" label-class="text-left">
              <input type="button" @click="exportCSV()" class="btn btn-md btn-outline-primary mr-2" value="Exportar CSV">
              <input type="button" @click="exportPDF()" class="btn btn-md btn-outline-primary" value="Exportar PDF">
            </b-form-group>
          </div>
        </div>
      </div>
    
    <b-row>
      <b-col>
        <vdtnet-table
          :fields="fields"
          :opts="opts"
          :selectCheckbox="0"
          ref="table"
        />
      </b-col>
    </b-row>
    
  </b-container>
</template>

<script>
import VdtnetTable from "vue-datatables-net";
import Daterange from "../global/Daterange-component.vue";
import formatMoneyMixin from "../../mixins/formatMoneyMixin";
import formatDateMixin from "../../mixins/formatDateMixin";
import exportsMixin from "../../mixins/exportsMixin";
import { mapState } from 'vuex'

export default {
  components: {
    VdtnetTable,
    Daterange,
  },
  mixins: [formatMoneyMixin,formatDateMixin,exportsMixin],
  data: function() {
    return {
      loading: false,
      invoiceType:'',
      invoiceMethodPaid:'',
      invoicePos:'',
      startDate:null,
      endDate:null,
      totalSales:0,
      totalCash:0,
      totalCC:0,
      totalTpChecks:0,
      totalCards:0,
      salespoints:[],
      opts: {
        language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
          },
        ajax: {
          url: process.env.VUE_APP_BASE_URL+"/api/reports/sales",
          type: "GET",
          data: (d) => {
            d.invoiceType = this.invoiceType,
            d.pos_id = this.invoicePos,
            //d.methodPaid = this.invoiceMethodPaid,
            d.daterange = this.daterange
            return d
          },
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        },
        processing: true,
        pageLength: 15,
        searching: true,
        searchDelay: 1500,
        destroy: true,
        order: [[0, 'desc']],
        ordering: true,
        lengthChange: true,
        serverSide: true,
        fixedHeader: true,
      },
      fields: {
        created_at: { label: "Fecha", searchable: true,
          render: (data) => this.formatoFechaHora(data),
        },
        type: { label: "Tipo de factura" },
        number: { label: "Número" },
        client_name: { label: "Cliente" },
        paid: { label: "Métodos de pago" },
        total: { label: "Monto",
          searchable: true,
          render: this.formatoDinamicoMoneda,
        },
      },
    };
  },
  computed:{
    ...mapState('authUser',{
         authUser: state => state.authUser,
      }),
      paymentsMethods:{
         get: function () { return  this.authUser.branchoffices[0].payments_methods;}
      },
      daterange() {
        if (this.startDate === null || this.endDate === null) {
         return null;
        }
        const startDate = this.formatoFecha2Daterange(this.startDate);
        const endDate = this.formatoFecha2Daterange(this.endDate);
        return `${startDate} - ${endDate}`;
      },
      filters(){
        return {
          invoiceType : this.invoiceType,
          pos_id : this.invoicePos,
          //methodPaid : this.invoiceMethodPaid,
          daterange : this.daterange
      }
    }
  },
  created () {
      this.$store.dispatch('authUser/getAuthUser')
   },
  mounted() {
    this.setDefaultDateRange();
    this.getSalepoints();
    this.getTotals();
  },
  methods: {
    reload(){
      this.getTotals();
      this.refreshTable();
    },
    refreshTable() {  
      var vm = this;
      if (vm.$refs.table !== undefined) vm.$refs.table.reload();
    },
    dateCreated(date) {
      this.startDate = date.startDate;
      this.endDate = date.endDate;
    },
    getSalepoints() {
      var vm = this;
      axios.get(process.env.VUE_APP_BASE_URL+'/api/configs/pos/list')
            .then(response => {
              if(response.data) {
                vm.salespoints = response.data.data;
              }
            })
            .catch(error => {
              alert(error);
            })
    },
    getTotals() {
      this.loading = true;
      this.$http.get(process.env.VUE_APP_BASE_URL+"/api/reports/statsBySales" , {params:this.filters})
      .then((response) => {
        var payments = response.data.data.payments;
        this.totalSales = payments.total;
        this.totalCash = payments.cash;
        this.totalCC = payments.cc;
        this.totalTpChecks = payments.tpcheks;
        this.totalCards = payments.creditcards;
        this.loading = false;
      });
      
    },
    exportCSV() {
      this.$http.post(process.env.VUE_APP_BASE_URL+"/api/reports/CSVsaleReport", this.filters)
      .then((response) => {
      });
    },
    exportPDF() {
      this.$http.post(process.env.VUE_APP_BASE_URL+"/api/reports/PDFSaleReport", this.filters)
      .then((response) => {
      });
    },
    setDefaultDateRange() {
      const today = new Date();
      const startDate = new Date();
      startDate.setDate(today.getDate() - 30);

      this.startDate = this.formatDate(startDate);
      this.endDate = this.formatDate(today);
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      return `${day}/${month}/${year}`;
    },
  },
  watch: {
    filters() {
      this.reload();
    },
  },

};
</script>

<style></style>
