<template>
  <b-nav align="center" class="lasubnav d-flex align-items-center py-1">   
    <b-nav-item><router-link :to="indexLink" class="text-white" active-class="active">Inventario</router-link></b-nav-item>
    <b-nav-item><router-link :to="categoriasDeInventarioLink" class="text-white" active-class="active">Categorías</router-link></b-nav-item>
    <b-nav-item><router-link :to="marcasdeProductosLink" class="text-white" active-class="active">Marcas de productos</router-link></b-nav-item>
    <b-nav-item><router-link :to="consultaDePrecioLink" class="text-white" active-class="active">Consulta de precio</router-link></b-nav-item>
    <v-guard :permissions="['STOCK_MOVIMIENTO']">
      <b-nav-item><router-link :to="existenciasEnStockLink" class="text-white" active-class="active">Existencias en stock</router-link></b-nav-item>
      <b-nav-item><router-link :to="movimientosDeStockLink" class="text-white" active-class="active">Movimientos de stock</router-link></b-nav-item>
    </v-guard>
    <v-guard :permissions="['PRECIOS_ACTUALIZADOR']">
    <b-nav-item><router-link :to="actualizacionDePreciosLink" class="text-white" active-class="active">Actualización de precios</router-link></b-nav-item>
    </v-guard>
    <b-nav-item><router-link :to="listasDePreciosLink" class="text-white" active-class="active">Listas de precios</router-link></b-nav-item>
    <b-nav-item><router-link :to="depositosLink" class="text-white" active-class="active">Depósitos</router-link></b-nav-item>  
    <!-- <b-nav-item><router-link :to="transferLink" active-class="active">Transferencia de stock</router-link></b-nav-item> -->
  </b-nav>
</template>

<script>
export default {
  computed: {
    indexLink() {
      return { name: 'Inventario' }
    },
    categoriasDeInventarioLink() {
      return { name: 'Categorias de inventario' }
    },
    marcasdeProductosLink() {
      return { name: 'Marcas de productos' }
    },
    existenciasEnStockLink() {
      return { name: 'Existencias en stock' }
    },
    consultaDePrecioLink() {
      return { name: 'Consulta de Precio' }
    },
    movimientosDeStockLink() {
      return { name: 'Movimientos de stock' }
    },
    actualizacionDePreciosLink() {
      return { name: 'Actualizacion de precios' }
    },
    listasDePreciosLink() {
      return { name: 'Listas de precios' }
    },
    
    catalogosLink() {
      return { name: 'Catalogos' }
    },
    depositosLink() {
      return { name: 'Depositos' }
    },
    /* transferLink() {
      return { name: 'Transferencia de Stock' }
    }, */
  }
}
</script>

<style scoped>
.lasubnav {
  animation: opacity 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
  background: #2d538a;
}

@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.active {
  font-weight: bold;
  color: white; 
  background-color: orange;
  border-radius: 10px 10px 0px 0px;
  padding: 11px;
}
</style>