import { render, staticRenderFns } from "./Edit-modal.vue?vue&type=template&id=25dd1aec"
import script from "./Edit-modal.vue?vue&type=script&lang=js"
export * from "./Edit-modal.vue?vue&type=script&lang=js"
import style0 from "@riophae/vue-treeselect/dist/vue-treeselect.min.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=1&prod&lang=css&external"
import style2 from "./Edit-modal.vue?vue&type=style&index=2&id=25dd1aec&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports