<template>
  <b-container fluid>
    <div class="row mt-3">
      <b-col class="d-flex justify-content-start">
        <h4>Ventas</h4>
      </b-col>
      <!-- -------------------- BOTON -------------------------- -->
      <b-col class="text-right">
        <b-button-group>
          <router-link :to="nuevaVentaLink"><b-button variant="orange" size="lg">Nueva Venta</b-button></router-link>
          <b-dropdown right variant="orange">
            <b-dropdown-item>
              <router-link :to="nuevaNotaDeCreditoLink">Nueva nota de crédito</router-link>
            </b-dropdown-item>
            <b-dropdown-item>
              <router-link :to="nuevaNotaDeDebitoLink">Nueva nota de débito</router-link>
            </b-dropdown-item> 
          </b-dropdown>
        </b-button-group>
      </b-col>
    </div>
    <b-row class="d-flex justify-content-between">
      <!-- -------------------- BUSCADOR -------------------------- -->
      <b-col cols="md-2">
        <input
          v-model="filters.qclient"  
          type="search" 
          class="form-control my-2" 
          placeholder="Buscar por cliente"
          aria-controls="coupons" />
      </b-col>
      <b-col cols="md-2">
        <input
          v-model="filters.qname" 
          type="search" 
          class="form-control my-2" 
          placeholder="Buscar por producto"
          aria-controls="coupons" />    
      </b-col>
      <b-col cols="md-2">
          <b-select class="form-control my-2" 
          v-model="selectedTypeFilter" 
          name="filter">
            <option value="all" selected="selected">Todos los comprobantes</option>
            <option value="invoices">Facturas</option>
            <option value="credit-notes">Notas de crédito</option>
            <option value="debit-notes">Notas de débito</option>
            <option value="other">Otros comprobantes</option>
            <option value="deleted">Comprobantes anulados</option>
          </b-select>
      </b-col>
      <!-- --------------------------FILTROS --------------------  -->  
    </b-row>
    <!-- ----------------------- TABLA -------------------------- -->
    <b-row>
      <b-col>
        <vdtnet-table 
          :fields="fields" 
          :opts="opts" 
          :selectCheckbox="0" 
          @saleDetail="getSaleDetail"
          @getPDF="downloadPDF" 
          @cancel="cancelOrDeleteSale" 
          @createCreditNote="createCreditNote" 
          @createDebitNote="createDebitNote"
          @updateSale="updateSale" 
          ref="table" 
          />
      </b-col>
    </b-row> 
  </b-container>
</template>

<script>
import VdtnetTable from "vue-datatables-net";
import { mapState } from 'vuex';
import formatDateMixin from "../../../mixins/formatDateMixin";
import formatMoneyMixin from "../../../mixins/formatMoneyMixin";
import myaxios from 'axios';


export default {
  props: {
    containerClassName: {
      type: String,
      default: 'table-responsive d-print-inline'
    },
    className: {
      type: String,
      default: 'table table-striped table-bordered nowrap w-110'
    },
  },
  components: {
    VdtnetTable,
  },
  mixins: [formatDateMixin,formatMoneyMixin],
  data: function () {
    return {
      filters:{
        qname:'',
        qclient:''
      },
      selectedTypeFilter: 'all',
      isLoading: false,
      doSearch: "",
      printTicket: null,
      opts: {
        language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
        },
        ajax: {
          url: process.env.VUE_APP_BASE_URL+"/api/sales/list",
          type: "GET",
          data: (d) => {
            d.filter = this.selectedTypeFilter;
            d.filters= this.filters;
            d.branchoffice_id = this.branchofficeId;
            return d.data
          },
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        },
        processing: true,
        pageLength: 15,
        searching: true,
        searchDelay: null,
        destroy: true,
        ordering: true,
        lengthChange: true,
        serverSide: true,
        fixedHeader: true
      },
      fields: {
        id: { visible: false },
        tipo: { label: "Tipo",searchable:true  },
        number: { label: "N°",searchable:true  },
        created_at: {
          label: "Fecha",
          searchable: true,
          render: (data) => this.formatoFechaHora(data),
        },
        client: { label: "Cliente",searchable:true },
        user: { label: "Vendedor",searchable:true },
        branchoffice: { label: "Sucursal",visible:false },
        status: { 
          label: "Estado",
          render: (data, type) => {
            const isInvoiced = data === "FACTURADA";
            const isCanceled = data === "ANULADA";
            
            if (isInvoiced) {
              return type === 'display' ? '<span class="text-success">Facturada</span>' : data;

            } else if (isCanceled) {
              return type === 'display' ? '<span class="text-danger">Anulada</span>' : data;
            } else {
              return type === 'display' ? '<span class="text-muted">Desconocido</span>' : data;
            }
          }
        
        },
        total: {
          label: "Total",
          searchable:true,
          render: this.formatoDinamicoMoneda,
        },
        actions: {
          isLocal: true,
          label: "Acciones",
          width: "85px",
          align: "center",
          render: (data, type,) => {
            const isInvoiced = data === "FACTURADA";
            const isCanceled = data === "ANULADA";

            const icons = isInvoiced ?
              `<div class="divActions btn-group-sm">
                <button data-action="saleDetail" data-toggle="tooltip" title="Ver detalle" type="button" class="btn btn-light">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-ruled" viewBox="0 0 16 16">
                    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5v2zM3 12v-2h2v2H3zm0 1h2v2H4a1 1 0 0 1-1-1v-1zm3 2v-2h7v1a1 1 0 0 1-1 1H6zm7-3H6v-2h7v2z"/>
                  </svg>
                </button>
                <button data-action="getPDF" data-toggle="tooltip" title="Descargar PDF" type="button" class="btn btn-light marginButton">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-pdf" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"/>
                  </svg>
                </button>
                <button data-action="createCreditNote" data-toggle="tooltip" title="Crear nota de crédito" type="button" class="btn btn-light marginButton">NC</button>
                <button data-action="createDebitNote" data-toggle="tooltip" title="Crear nota de debito" type="button" class="btn btn-light marginButton">ND</button>
                <button data-action="cancel" data-toggle="tooltip" title="Anular/Eliminar comprobante" type="button" class="btn btn-light marginButton">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                    <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                  </svg>
                </button>
              </div>` :
              isCanceled ?
              `<div class="divActions btn-group-sm">
                <button data-action="saleDetail" data-toggle="tooltip" title="Ver detalle" type="button" class="btn btn-light">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-ruled" viewBox="0 0 16 16">
                    <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5v2zM3 12v-2h2v2H3zm0 1h2v2H4a1 1 0 0 1-1-1v-1zm3 2v-2h7v1a1 1 0 0 1-1 1H6zm7-3H6v-2h7v2z"/>
                  </svg>
                </button>
                <button data-action="getPDF" data-toggle="tooltip" title="Descargar PDF" type="button" class="btn btn-light marginButton">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-pdf" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"/>
                  </svg>
                </button>
                <button data-action="createCreditNote" data-toggle="tooltip" title="Crear nota de crédito" type="button" class="btn btn-light marginButton">NC</button>
                <button data-action="createDebitNote" data-toggle="tooltip" title="Crear nota de debito" type="button" class="btn btn-light marginButton">ND</button>
              </div>`: '';

            return icons;
          }
        },
      },
      sale: {
        id: null,
        number: "",
      },
      emptySale: {
        id: null,
        number: "",
      },
      sales: null,
    };
  },
  watch: {
    doSearch(newValue, oldValue) {
      if (newValue.length >= 4 || oldValue.length >= 4) {
        this.$refs.table.search(this.doSearch);
      }
      if (newValue === "" && oldValue.length > 0) {
        this.$refs.table.search(this.doSearch);
      }
    },
    'filters.qclient'(newValue) {
      if (this.searchDelay) {
        clearTimeout(this.searchDelay);
      }
      
      this.searchDelay = setTimeout(() => {
        this.refreshTable();
      }, 700);
    },
    'filters.qname'(newValue) {
      if (this.searchDelay) {
        clearTimeout(this.searchDelay);
      }
      
      this.searchDelay = setTimeout(() => {
        this.refreshTable();
      }, 700);
    },
    selectedTypeFilter() {
      this.refreshTable();
    },
    branchofficeId(newValue, oldValue) {
      this.refreshTable();
    },
  },
  methods: {
    refreshTable() {
      this.$refs.table.reload();
    },
    getSaleDetail(data) {
      window.open(
        this.$router.resolve({
        name: "Detalle de venta",
        params: { saleId: data.id },
      }).href,'_blank');
    },
    createCreditNote(data) {
      this.$router.push({ name: 'Nueva NC desde venta', params: { saleId: data.id } })
    },
    createDebitNote(data) {
      this.$router.push({ name: 'Nueva ND desde venta', params: { saleId: data.id } })
    },
    updateSale(data) {
      localStorage.removeItem('header');
      localStorage.removeItem('products');
      this.$router.push({ name: 'Editar venta', params: { saleId: data.id } })
    },
    cancelOrDeleteSale(data) {
      if (data.letter!='P') {
        alert('No se puede eliminar factura electronica');
        return;
      }
      if (data.status === "ANULADA") {
        if (
          confirm("¿Está seguro que desea borrar este comprobante?") == true
        ) {
          this.$http
            .post("/api/sales/delete", { id: data.id })
            .then((response) => {              
              if (response.data.code == 201) {
                this.refreshTable();
              }
            });
        }
      } else if(data.status === "FACTURADA") {
        if (
          confirm("¿Está seguro que desea cancelar este comprobante?") == true
        ) {
          this.$http
            .post("/api/sales/cancel", { id: data.id })
            .then((response) => {              
              if (response.data.code == 201) {
                this.refreshTable();
              }
            });
        }
      }else {
        if (
          confirm("¿Está seguro que desea borrar este comprobante?") == true
        ) {
          this.$http
            .post("/api/sales/delete", { id: data.id })
            .then((response) => {              
              if (response.data.code == 201) {
                this.refreshTable();
              }
            });
        }
      }
    },
    downloadPDF(data) {
      console.log(data);
      this.$http.get('/api/configs/branchoffice/getData')
      .then(response => {
        let branchofficeSale = data.bo_id;
        let branchoffices = response.data.data.branchoffices;
        let branchoffice = branchoffices.find(bo => bo.id === branchofficeSale);
        if(branchoffice.options.print_bill_ticket === 1){
          this.printTicket = branchoffice.options.print_bill_ticket;
          console.log('printTicket: '+this.printTicket);
        }else{
          this.printTicket = 0;
        }
        if(this.printTicket === 0){
          let id = data.id;
          let vm = this;
          let filename = data.client+data.updated_at+'.pdf';
          vm.loading = true;
          axios.get("/api/sales/getURL/"+id)
          .then((res) => {
            const uninterceptedAxiosInstance = myaxios.create();
            uninterceptedAxiosInstance.get(res.data, { responseType: 'blob' })
            .then((response) => {
              const blob = new Blob([response.data], {type: 'application/pdf'});
              const link = document.createElement('a')
              link.href = URL.createObjectURL(blob)
              link.download = filename
              link.click()
              URL.revokeObjectURL(link.href)
            })
          })
        }else{
          console.log(data.id);
          axios.get('/api/sales/reprintSale/'+data.id)
          .then(res => {
            const uninterceptedAxiosInstance = myaxios.create();
            uninterceptedAxiosInstance.get(res.data.data.urlInvoice, { responseType: 'blob' })
            .then((response) => {
              const blob = new Blob([response.data], {type: 'application/pdf'});
              const link = document.createElement('a')
              const currentDate = new Date();
              const formattedDate = currentDate.toLocaleDateString().replace(/\//g, '-');
              link.href = URL.createObjectURL(blob)
              link.download = 'Reimpresion_' + formattedDate + '.pdf';
              link.click()
              URL.revokeObjectURL(link.href)
            })
          })
        }
      })

    },
    refreshTable() {
      var vm = this;
      if (vm.$refs.table !== undefined) vm.$refs.table.reload();
    },
  },
  computed: {
    nuevaVentaLink() {
      localStorage.removeItem('header');
      localStorage.removeItem('products');
      return { name: "Nueva venta" };
    },
    nuevaNotaDeCreditoLink() {
      return { name: "Nueva nota de credito" };
    },
    nuevaNotaDeDebitoLink() {
      return { name: "Nueva nota de debito" };
    },
    nuevaNotaDePedidoLink() {
      return { name: "Nueva nota de pedido" };
    },
    nuevoPresupuestoLink() {
      return { name: 'Nuevo presupuesto' }
    },
    nuevoRemitoDeVentaLink() {
      return { name: 'Nuevo remito de venta' }
    },
    indexInventarioLink() {
      return { name: "Inventario" };
    },
    indexClientesLink() {
      return { name: "Clientes" };
    },
    ...mapState('branchoffices', {
      branchofficeId: state => state.selectedBranchofficeId,
    }),
  },
};
</script>

