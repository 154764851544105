<template>
  <b-container fluid>
    <b-row>
      <b-col class="mt-2">
        <h2>Totales de la factura</h2>
      </b-col>
    </b-row>
    <b-row v-if="myTotals" class="mt-2">
      <b-col cols="md-3">
        <label>Total bruto</label>
        <input type="text" class="form-control" v-model="myTotals.gross"/>
      </b-col>
      <b-col cols="md-1">
        <label>IVA 2,5</label>
        <input type="text" class="form-control" v-model="myTotals.taxes.two"/>
      </b-col>
      <b-col cols="md-1">
        <label>IVA 5</label>
        <input type="text" class="form-control" v-model="myTotals.taxes.five"/>
      </b-col>
      <b-col cols="md-1">
        <label>IVA 10,5</label>
        <input type="text" class="form-control" v-model="myTotals.taxes.ten"/>
      </b-col>
      <b-col cols="md-1">
        <label>IVA 21</label>
        <input type="text" class="form-control" v-model="myTotals.taxes.twentyOne"/>
      </b-col>
      <b-col cols="md-1">
        <label>IVA 27</label>
        <input type="text" class="form-control" v-model="myTotals.taxes.twentySeven"/>
      </b-col>
      <b-col cols="md-2">
        <label>Excento</label>
        <input type="text" class="form-control" v-model="myTotals.taxes.lofty"/>
      </b-col>
      <b-col cols="md-2">
        <label>Descuento</label>
        <input type="text" class="form-control" v-model="myTotals.discount"/>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="md-2">
        <label>Impuestos internos</label>
        <input type="text" class="form-control" v-model="myTotals.taxes.internalTax"/>
      </b-col>
      <b-col cols="md-2">
        <label>Percepcion IVA</label>
        <input type="text" class="form-control" v-model="myTotals.taxes.perceptionTax"/>
      </b-col>
      <b-col cols="md-2">
        <label>Perc. IIBB</label>
        <input type="text" class="form-control" v-model="myTotals.taxes.iibb"/>
      </b-col>
      <b-col cols="md-2">
        <label>Otros impuestos</label>
        <input type="text" class="form-control" v-model="myTotals.taxes.otherTax"/>
      </b-col>
      <b-col cols="md-4">
        <label>Total</label>
        <input type="text" class="form-control" v-model="myTotals.total"/>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
    props: ['company_typetax', 'endpointurl', 'showChoosedate', 'payment_methods', 'totals'],
    data() {
      return {
        myTotals: {
          gross: 0,
          taxes: {
            two: 0,
            five: 0,
            ten: 0,
            twentyOne: 0,
            twentySeven: 0,
            twentySeven: 0,
            lofty: 0,
            internalTax: 0,
            perceptionTax: 0,
            iibb: 0,
            otherTax: 0
          },
          total: 0,
          discount:0
        }
      }
    },
    mounted: function () {
      this.myTotals = this.totals;
    },
    watch: {
      'myTotals': { // Esto envia los datos de header cada vez que cambian al receipt donde seran adjuntados al payload
        handler: function (a) {
          this.$emit('totalschanged', this.myTotals);
        }, deep: true,
      },
    }
}
</script>
<style>

</style>
