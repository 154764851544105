<template>
  <b-container fluid>
    <div class="row my-3">
      <b-col class="d-flex justify-content-start">
        <h4>Movimientos de stock</h4>
      </b-col>
      <b-col class="text-right">
        <b-button @click="openModalTrans" variant="orange" size="lg">Transferencia entre depositos <i class="bi bi-arrow-left-right"></i></b-button>
      </b-col>
    </div>
    <!-- MODAL MOVIMIENTOS -->
    <transition name="modal">
      <div v-if="isModalVisible" class="modal-mask-product">
        <div class="modal-dialog">
          <div class="modal-content p-3 rounded">
            <div class="modal-header">
              <h4>Movimiento de stock</h4>
              <b-button variant="primary size-sm" @click="close()"><i class="bi bi-x-lg"></i></b-button>
            </div>
            <div class="modal-body">
              <NuevoMovimientoStock
              :productId="productIdParent"
              :prodId="productId"
              @confirmsavemovement="modalSucess()"
              />
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- MODAL TRANSFERENCIA -->
    <transition name="modal">
      <div v-if="isModalVisibleTrans" class="modal-mask-product">
        <div class="modal-dialog">
          <div class="modal-content p-3 rounded">
            <div class="modal-header">
              <h4>Transferencia entre depositos</h4>
              <b-button variant="primary size-sm" @click="close()"><i class="bi bi-x-lg"></i></b-button>
            </div>
            <div class="modal-body">
              <MovimientoEntreSucursales   
              :productId="productIdParent"
              @confirmsavemovement="modalSucess()" 
              />
            </div>
          </div>
        </div>
      </div>
    </transition>

    <!-- BUSCADORES -->
    <div class="card bg-light rounded-sm p-3 border">
      <div class="row d-flex justify-content-end" @submit.stop.prevent="doSearch">
      <!-- BUSCADORES -->
        <div v-if="productIdParent==null" class="col-8 d-flex justify-content-start">
          <div class="row">
            <b-col>
              <input placeholder="Buscar codigo interno" v-model="filters.qcode" class="form-control">
            </b-col>
            <b-col>
              <input placeholder="Buscar codigo de barra" v-model="filters.qbarcode" class="form-control">
            </b-col>
            <b-col>
              <input placeholder="Buscar codigo de fabrica" v-model="filters.qmanufacturercode" class="form-control">
            </b-col>
            <b-col>
              <input placeholder="Buscar nombre" v-model="filters.qname" class="form-control">
            </b-col>
          </div>
        </div>
        <div class="col-4 d-flex justify-content-between">
          <div class="row">
            <!-- FILTRO FECHA -->
            <b-col cols="md-4">
              <div class="form-group ">
                <Daterange 
                :opendir="'left'"
                :filtered="true"  
                @datepicked="dateUpdated"
                />
              </div>
            </b-col>
            <!-- FILTRO MOVIMIENTOS-->
            <b-col cols="md-4">
              <b-form inline>
                <label class="control-label" for="input-live">Movimiento:</label>
                <b-form-select class="form-control" v-model="movement_type">
                  <option value="all">TODOS</option>
                  <option value="in">ENTRADA</option>
                  <option value="out">SALIDA</option>
                </b-form-select>    
              </b-form>
            </b-col>
            <b-col cols="md-4">
              <b-button @click="openModal" variant="success">Nuevo Movimiento <i class="bi bi-arrow-down-up"></i></b-button>
            </b-col>
          </div>
        </div>
      </div>
    </div>
    <!-- TABLA CON EDITOR-->
    <b-row>
      <b-col cols="12">
        <vdtnet-table
          :fields="fields"
          :opts="opts"
          :selectCheckbox="0"
          ref="table"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

  <script>
  import VdtnetTable from "vue-datatables-net";
  import NuevoMovimientoStock from "./Nuevo-movimiento-de-stock.vue";
  import MovimientoEntreSucursales from "./Movimiento-entre-sucursales.vue";
  import Daterange from '../../global/Daterange-component.vue';
  
  export default {
    props: ['productIdParent' /*,'config'*/],
    components: {
      VdtnetTable,
      NuevoMovimientoStock,
      MovimientoEntreSucursales,
      Daterange
    },
    data: function() {
      return {
        isModalVisibleTrans: false,
        isModalVisible: false,
        filters:{
              qname:'',
              qcode:'',
              qbarcode:'',
              qmanufacturercode:'',
              qwarehouse: null,
              page:1,
              itemsPerPage:15,
              active:1,
              provider_id:null,
              brand_id:null,
              category_id:null,
              sorting:{
                  col:'',
                  order:'desc'
              }
          },
        movement_type:'all',
        daterange:{
          fromDate: null,
          toDate: null
        },
        doSearch: '',
        isLoading: false,
        showNewMovement: true,
        opts: {
          language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
          },
          ajax: {
            url: process.env.VUE_APP_BASE_URL+"/api/movements/list",
            type: "GET",
            data: (d) => {
              d.movement_type = this.movement_type;
              d.daterange = this.daterange;
              d.product_id = this.productIdParent ? this.productIdParent : this.productId;
              d.filters= this.filters;
              return d.data
            },
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          },
          processing: true,
          pageLength: 15,
          searching: true,
          searchDelay: 1500,
          destroy: true,
          ordering: true,
          order: [[0, 'desc']],
          lengthChange: true,
          serverSide: true,
          fixedHeader: true
        },
        fields: {
          created_at: { label: "Fecha y hora", defaultOrder:'desc' },
          reference_id: { label: "Tipo" },
          mov: { label: "Ingreso" },
          code: { label: "Codigo Int." },
          product: { label: "Producto" },
          quantity: { label: "Cantidad" },
          warehouse: { label: "Deposito" },
        },
        productId: null,
      };
    },
    created() {
      this.getAlertProduct();
    },
    mounted() {
      this.setDefaultDateRange();
      this.checkProductIdParent();
    },
    watch: {
      'filters.page': {handler: function (a) {this.refreshTable();}},
      'filters.active': {handler: function (a) {this.refreshTable();}},
      'filters.itemsPerPage': {handler: function (a) {this.refreshTable();}},
      'filters.qname': {handler: function (a) {this.refreshTable();}},
      'filters.qcode': {handler: function (a) {this.refreshTable();}},
      'filters.qbarcode': {handler: function (a) {this.refreshTable();}},
      'filters.qmanufacturercode': {handler: function (a) {this.refreshTable();}},
      'filters.qwarehouse': {handler: function (a) {this.refreshTable();}},
      'filters.category_id': {handler: function (a) {this.refreshTable();}},
      'filters.brand_id': {handler: function (a) {this.refreshTable();}},
      'filters.provider_id': {handler: function (a) {this.refreshTable();}},
      movement_type() {
        this.refreshTable();
      },
    },
    methods: {
    refreshTable() {
      var vm = this;
      if (vm.$refs.table !== undefined) vm.$refs.table.reload();
    },
    checkProductIdParent(){
      const modal = this.$route.params.modal;
      if(modal === 'edit'){
        this.isModalVisible = true;
      }else if(modal === 'transfer') {
        this.isModalVisibleTrans = true;
      }else {
        this.isModalVisible = false;
        this.isModalVisibleTrans = false;
      } 
    },
    openModal() {
      this.isModalVisible = true;
    },
      close() {
      this.isModalVisible = false;
      this.isModalVisibleTrans = false;
    },
    openModalTrans() {
      this.isModalVisibleTrans = true;
    },
    modalSucess(){
      this.close();
      this.refreshTable();
    },
      dateUpdated: function (date) {
        this.daterange.fromDate = date.startDate;
        this.daterange.toDate = date.endDate;
        this.refreshTable();
      },
      getAlertProduct(){
        this.productId = localStorage.getItem('productId');
        if (this.productId) {
            setTimeout(() => {
                  localStorage.removeItem('productId');
              }, 5000); 
        } else {
            console.log('Parámetro no encontrado en el almacenamiento local');
        }
      },
      setDefaultDateRange() {
        const today = new Date();
        const startDate = new Date();
        startDate.setDate(today.getDate() - 30);

        this.daterange.fromDate = this.formatDate(startDate);
        this.daterange.toDate = this.formatDate(today);
    },
    formatDate(date) {
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${day}/${month}/${year}`;
    },
    },
  };
  </script>

  <style>
  .active {
    font-weight: 800;
  }
  </style>