<template>
  <div>
    <receipt-add-product v-if="showModal" v-on:sendProduct='sendProduct' v-on:close="showModal=false"/>
    <div>
      <h2>Ingresar productos de compra</h2>
    </div>
    <table id="invoice_tabl" class="table search">
      <tbody>
        <tr>
          <td>
            <receipt-products-search separator="*"
            :product_default="product_default" :show-barcodes="showBarcodes" v-on:productadded="getProduct"/>
          </td>
          <td class="col-button">
            <div class="input-group-btn">
              <button v-if="config.customlabel!='false'" type="button" class="btn btn-info" @click="showModal = true"><i class="bi bi-pencil-square"></i></button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class='scroll'>
      <table id="invoice_table" class="table">
          <thead>
            <tr class="products-sub-header">
              <th v-if="showBarcodes" class="col-small"> Código </th>
              <th> Item </th>
              <th class="col-small"> Cantidad </th>
              <th v-if="showTaxes" class="col-small"> % IVA </th>
              <th v-if="showPrices" class="col-small"> Precio unitario </th>
              <th v-if="showPrices" class="col-small">Total</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          <tr v-for="prod in products" :key="prod.id">
            <td v-if="showBarcodes">{{prod.code}}</td>
            <td>{{prod.name}}</td>
            <td>
              <div class="form-group">
                <input v-model.number="prod.quantity" min="0" step="0.0001" class="form-control input-sm quantity" type="number">
              </div>
            </td>
            <td v-if="showTaxes">({{prod.ivatype}})</td>

            <td v-if="showPrices && (disciva)">
              <div class="form-group">
                <input v-model.number="prod.gross" min="0" step="0.0001" class="form-control input-sm quantity" type="number">
              </div>
            </td>

            <td v-if="showPrices && (!disciva)">
              <div class="form-group">
                <input v-model.number="prod.price" min="0" step="0.0001" class="form-control input-sm quantity" type="number">
              </div>
            </td>

            <td v-if="showPrices && (disciva)">${{ round(prod.quantity * prod.gross,2)}}</td>
            <td v-if="showPrices && (!disciva)">${{ round(prod.quantity * prod.price,2)}}</td>
            <td>
              <span @click="deleteproduct(prod.id)" aria-hidden="true" title="Borrar producto" style="cursor: pointer;">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"/>
                <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"/>
              </svg>
              </span>
            </td>
          </tr>
          <tr v-if="products.length<1">
            <td v-if="showBarcodes" colspan="5" rowspan="5" class="empty-cart">
              Todavía no hay productos. Buscá con la lupa o ingresá el código mas arriba
            </td>
            <td v-if="!showBarcodes" colspan="5" rowspan="5" class="empty-cart">
              Todavía no hay productos. Buscá por su nombre mas arriba
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>

import receiptProductsSearch from './Receipt-products-search.vue';
import receiptAddProduct from './Receipt-addProduct.vue';
import roundMixin from "../../../../mixins/roundMixin.js";

export default {
  components:{
    receiptProductsSearch,
    receiptAddProduct,
    
  },
  mixins: [roundMixin ],

  props: ['showPrices', 'config', 'prexistprd', 'showBarcodes', 'showTaxes', 'product_default', 'disciva'],
  data: function() {
    return {
      prex: this.prexistprd,
      products: [],
      showModal: false
    };
  },
  watch: {
    products: {
      handler: function(products) {
        for (var i = 0; i < this.products.length; i++) {
          if (this.products[i].quantity<0) this.products[i].quantity=0;
        }
        this.$emit('productschanged', this.products);
      }, deep: true,
    },
  },
  mounted: function () {
    if (this.prex) {
      for (var i = 0; i < this.prexistprd.length; i++) {
        this.products.push(this.prex[i]);
      }
      this.prex=null;
      this.$emit('productschanged', this.products);
    }
  },
  methods: {
    deleteproduct: function(id) {
        for (var i = 0; i < this.products.length; i++) {
          if (this.products[i].id === id) {
            this.products.splice(i,1);
          }
        }
    },
    sendProduct (product) {
      this.$emit('sendProduct', product);
      this.products.push(product);
      this.showModal = false;
    },
    getProduct: function(item) {
      item.price = parseFloat(item.price);

      for (var i = 0; i < this.products.length; i++) {
        if (this.products[i].id === item.id) {
          this.products[i].quantity = this.products[i].quantity + item.quantity;
          this.products[i].total = this.products[i].quantity * this.products[i].price;
          return;
        }
      }

      this.products.push(item);
    }
  },

  
 

}

</script>

<style lang="scss">
.products-sub-header {
  border-bottom:1px solid #ccc;
  font-weight: bold;
  background: rgba(242, 244, 247, 0.3)
}

.delete-product {
   font-size:22px;
   color:#b3b2b2;
   cursor:pointer;
   &:hover {
     color: #BE3631;
   }
}

.ok-payment-method {
   font-size:22px;
   color:#b3b2b2;
   cursor:pointer;
   &:hover {
     color: #3a9a09;
   }
}

.scroll {
  overflow-y: scroll;
  max-height: 40vh;
  min-height: 40vh;
  height: 40vh;
}

.empty-cart {
  height:30vh;
  text-align:center !important;
  padding:15px 0;
  font-weight:bold;
  font-size:1.75rem;
}
</style>
