<template>
  <b-container fluid>
    <div class="loading" v-if="loading">Loading&#8230;</div>
    <b-row class="mt-3">
      <b-col class="d-flex justify-content-start">
          <h4>Vision General de Compras</h4>
      </b-col>
    </b-row>
    <b-row class="statsreport">
      <b-col cols="lg-4 md-4 sm-12 xs-12">
        <div class="stat">
          <div class="number">{{ formatoDinamicoMoneda(purchases.total) }}</div>
          <div class="desc"> Total en compras </div>
        </div>
      </b-col>
      <b-col cols="lg-4 md-4 sm-12 xs-12 border-r-l">
        <div class="stat">
          <div class="number">{{ formatoDinamicoMoneda(purchases.cash) }}</div>
          <div class="desc"> Efectivo </div>
        </div>
      </b-col>
      <b-col cols="lg-4 md-4 sm-12 xs-12">
        <div class="stat">
          <div class="number"> {{ formatoDinamicoMoneda(purchases.cc) }}</div>
          <div class="desc"> Cuenta corriente </div>
        </div>
      </b-col>
    </b-row>

    <div class="p-3 bg-light rounded-sm my-2 border">
      <div class="row d-flex justify-content-between">
        <div class="col col-md-2">
          <b-form-group label="Tipo" label-class="text-left">            
            <select class="form-control btn-light load-data" v-model="invoiceType" name="invoiceType">
              <option selected="selected" value="">Todos los tipos</option>
              <option value="FC">FC</option>
              <option value="NC">NC</option>
              <option value="ND">ND</option>
            </select>
          </b-form-group>
        </div>
        <div class="col col-md-2 d-flex justify-content-left">
          <b-form-group label="Fecha" label-class="text-left">
            <Daterange :opendir="'left'" :filtered="true" @datepicked="dateCreated" />
          </b-form-group>
        </div>
        <div class="col col-md-2 d-flex justify-content-left">
          <b-form-group label="Exportar" label-class="text-left">
            <input type="button" @click="exportCSV()" class="btn btn-md btn-outline-primary mr-2" value="Exportar CSV">
            <input type="button" @click="exportPDF()" class="btn btn-md btn-outline-primary" value="Exportar PDF">
          </b-form-group>
        </div>
      </div>
    </div>

  <b-row>
    <b-col cols="md-12">
      <vdtnet-table
        :fields="fields"
        :opts="opts"
        :selectCheckbox="0"
        ref="table"
      />
    </b-col>
  </b-row>
  </b-container>

</template>

<script>
import VdtnetTable from "vue-datatables-net";
import formatDateMixin from "../../mixins/formatDateMixin";
import formatMoneyMixin from "../../mixins/formatMoneyMixin";
import exportsMixin from "../../mixins/exportsMixin";
import Daterange from "../global/Daterange-component.vue";

export default {
  components: {
    VdtnetTable,
    Daterange,
  },
  mixins: [formatMoneyMixin,formatDateMixin,exportsMixin],
  data: function() {
    return {
      loading: false,
      pageLength: 15,
      invoiceType: "",
      startDate: null,
      endDate: null,
      branchoffice_id:null,
      purchases:[],
      opts: {
        language: {
          url: '//cdn.datatables.net/plug-ins/1.13.4/i18n/es-ES.json',
          },
        ajax: {
          url: process.env.VUE_APP_BASE_URL+"/api/reports/purchases",
          type: "GET",
          data: (d) => {
            d.invoiceType= this.invoiceType,
            d.daterange= this.daterange,
            d.branchoffice_id = this.branchoffice_id;
            return d
          },
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        },
      },
      fields: {
        created_at: { label: "Fecha",
        searchable: true,
          render: (data) => this.formatoFechaHora(data), },
        type: { label: "Tipo de factura" },
        number: { label: "Número" },
        provider_name: { label: "Proveedor" },
        total: { label: "Monto",searchable: true,
        render: this.formatoDinamicoMoneda},
      },
    };
  },
  mounted() {
    this.setDefaultDateRange();
    this.getTotals();
  },
  computed:{
    daterange() {
      if (this.startDate === null || this.endDate === null) {
        return null;
      }
      const startDate = this.formatoFecha2Daterange(this.startDate);
      const endDate = this.formatoFecha2Daterange(this.endDate);
      return `${startDate} - ${endDate}`;
    },
    filters() {
      return {
        invoiceType: this.invoiceType,
        daterange: this.daterange,
        branchoffice_id : this.branchoffice_id
      };
    }
  },
  watch: {
    filters: {
      handler() {
        this.reload();
      },
      deep: true,
    },
  },
  methods: {
    dateCreated(date) {
      this.startDate = date.startDate;
      this.endDate = date.endDate;
    },
    reload() {
      if (!this.isLoading) {
        this.getTotals();
        this.refreshTable();
      }
    },
    refreshTable() {
      if (this.$refs.table !== undefined)
        this.$refs.table.reload();
    },
    getTotals() {
      var vm = this;
      vm.isLoading = true;
      this.$http.get(process.env.VUE_APP_BASE_URL+"/api/reports/statsByPurchases" , {params:this.filters})
      .then((response) => {
        vm.purchases = response.data.data;
        vm.isLoading = false;
      });
    },
    exportCSV() {
      this.$http.post(process.env.VUE_APP_BASE_URL+"/api/reports/CSVpurchaseReport", this.filters)
      .then((response) => {
      });
    },
    exportPDF() {
      this.$http.post(process.env.VUE_APP_BASE_URL+"/api/reports/PDFpurchaseReport", this.filters)
      .then((response) => {
      });
    },
    setDefaultDateRange() {
      const today = new Date();
      const startDate = new Date();
      startDate.setDate(today.getDate() - 30);

      this.startDate = this.formatDate(startDate);
      this.endDate = this.formatDate(today);
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2);
      const day = ('0' + date.getDate()).slice(-2);
      return `${day}/${month}/${year}`;
    },
  },
};
</script>

<style></style>
