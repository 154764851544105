
<template>
<b-container fluid>
  <div class="row my-3">
    <b-col class="d-flex justify-content-start">
      <h4>Detalle de venta</h4>
    </b-col>
  </div>
  <!-- ------- DATOS DE FACTURA -------- -->
  <b-card class="bg-light d-flex justify-content-between mb-4 p-0">
    <b-row class="mt-3">
      <b-col cols="sm-3">
        <b-form-group>
          <div>
            <h2 class="control-label" v-if="sale.number && sale.pos">{{ sale.type }} {{ sale.letter }} {{ padLeft(sale.pos, 4) }} - {{ padLeft(sale.number, 8) }}</h2>
          </div>
        </b-form-group>
      </b-col>
      
      <b-col cols="sm-3">
        <b-form-group>
          <h2 class="control-label">Cliente: {{ sale.client.name }}</h2>
        </b-form-group>
      </b-col>
      <b-col cols="sm-3" v-if="sale.salesclerk != null">
        <b-form-group>
          <h2 class="control-label">Vendedor: {{sale.salesclerk}}</h2>
        </b-form-group>
      </b-col>
      <b-col cols="sm-3">
        <b-form-group>
            <h2 class="control-label">Fecha: {{createdDateFormatted}}</h2>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="sale.response != null">
      <b-col cols="md-6">
        <p class="text-secondary">CAE: {{sale.response['CAE']}}</p>
      </b-col>
      <b-col cols="md-6">
        <p class="text-secondary">CAE Fech. Venc.: {{sale.response['CAEFchVto']}}</p>
      </b-col>
    </b-row>
    <b-row v-if="sale.service_fromdate != null">
      <b-col>
        <b-form-group>
            <label class="control-label"><b>Periodo</b> | Desde: {{serviceFromDateFormatted}} | Hasta: {{serviceToDateFormatted}} | Venc. FC: {{serviceExpDateFormatted}} |</label>
        </b-form-group>
      </b-col>  
    </b-row>
  </b-card>
  <!-- --------- TABLAS ------ -->
  <b-row style="margin-bottom: 100px;">
    <!-- ------ TABLA ------ -->
    <b-col cols="md-8">
      <b-table-simple sticky-header small caption-top responsive>
        <b-thead head-variant="light">
          <b-tr>
            <b-th class="col-small"> Cod </b-th>
            <b-th> Item </b-th>
            <b-th class="col-small"> Cantidad</b-th>
            <b-th class="col-small"> Precio unitario</b-th>
            <b-th class="col-small"> Desc<span class="th-mobile">uento</span></b-th>
            <b-th class="col-small"> Total</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          
          <b-tr
            v-for="value in sale.sale_details"
            :key="value.budget_id"
            role="row"
            class="add"
          >
            <b-td>{{value.code}}</b-td>
            <b-td>{{value.description}}</b-td>
            <b-td>{{value.quantity}}</b-td>
            <b-td>{{formatoDinamicoMoneda(value.price + value.discount)}}</b-td>
            <b-td>{{formatoDinamicoMoneda(value.discount)}}</b-td>
            <b-td>{{formatoDinamicoMoneda(value.price * value.quantity)}}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-col>
    <!-- ------ TABLA TOTALES ------ -->
    <!-- ------ TOTALES ------ -->
    <b-col cols="md-4">
      <div class="card mb-4">
        <div class="card-header">
          <h3>Totales de la venta</h3>
        </div>
        <div class="card-body">
          <b-form-group>
            <b-row>
              <!-- ------ Sub total ------ -->
              <b-col cols="md-6 text-left">
                SUBTOTAL:
              </b-col>
              <b-col cols="md-6 text-left">
                <span>{{formatoDinamicoMoneda(sale.subtotal)}}</span>
              </b-col>
            </b-row>
            <!-- ------ Descuento ------ -->
            <b-row v-if="sale.discount > 0">
              <b-col cols="md-6 text-left">
                DESCUENTO:
              </b-col>
              <b-col cols="md-6 text-left">
                <span>{{formatoDinamicoMoneda(sale.discount)}}</span>
              </b-col>
            </b-row>
            <!-- ----------- IVA ---------- -->
            <div v-if="sale.letter === 'A' || sale.letter=== 'M'">
              <b-row>
                <b-col cols="md-6 text-left">
                  NETO GRAVADO:
                </b-col>
                <b-col cols="md-6 text-left">
                  <span>{{formatoDinamicoMoneda(sale.nettaxed)}}</span>
                </b-col>
              </b-row>
              <b-row v-for="(tax, key) in sale.taxes" :key="key">
                <b-col cols="md-6 text-left">
                  IVA {{key}}%:
                </b-col>
                <b-col cols="md-6 text-left">
                  <span>{{formatoDinamicoMoneda(tax)}}</span>
                </b-col>
              </b-row>
            </div>
            <br>
            <!-- ---- TOTAL -------- -->
            <b-row style="font-weight: bold;">
              <b-col cols="md-6 text-left mt-3">
                TOTAL:
              </b-col>
              <b-col cols="md-6 text-left mt-3">
                <span id="htmlTotal">{{formatoDinamicoMoneda(sale.total)}}</span>
              </b-col>
            </b-row>
            <!-- ---- Efectivo -------- -->
            <div v-if="sale.fundMovement > 0">
              <b-row>
                <b-col cols="md-6 text-left">
                  PAGO EN EFECTIVO:
                </b-col>
                <b-col cols="md-6 text-left">
                  <span id="htmlTotal">{{formatoDinamicoMoneda(sale.fundMovement.amount)}}</span>
                </b-col>
              </b-row>
            </div>
            <!-- ---- Cupon tarjeta -------- -->
            <div v-if="sale.coupons > 0">
              <div v-for="coupon in sale.coupons"
                :key="coupon.sale_id"
                role="row"
                class="add">
                <b-row>
                  <b-col cols="md-6 text-left">
                  PAGO CON: {{coupon.creditcard.name}} - {{coupon.number}}
                  </b-col>
                  <b-col cols="md-6 text-left">
                    $ <span id="htmlTotal">{{formatoDinamicoMoneda(coupon.amount)}}</span>
                  </b-col>
                </b-row>
              </div>
            </div>
            <!-- ---- Cheques-------- -->
            <div v-if="sale.tpchecks > 0">
              <div v-for="tpcheck in sale.tpchecks"
                :key="tpcheck.sale_id"
                role="row"
                class="add">
                <b-row>
                    <b-col cols="md-6 text-left">
                      PAGO CON CHEQUE: {{tpcheck.number}}
                    </b-col>
                    <b-col cols="md-6 text-left">
                      $ <span id="htmlTotal">{{tpcheck.amount}}</span>
                    </b-col>
                </b-row>
              </div>
            </div>
            <div v-if="totalca > 0">
              <b-row>
                <b-col cols="md-6 text-left">
                  PAGO EN CTA. CTE.:
                </b-col>
                <b-col cols="md-6 text-left">
                  $ <span id="htmlTotal">{{totalca}}</span>
                </b-col>
              </b-row>
            </div>
          </b-form-group>
        </div>
      </div>
      <!-- ------ NOTA PARA CLIENTE ------ -->
      <b-form-group div v-if="sale.notes !=''" class="form-group">
        <label>Nota para el cliente</label>
        <textarea v-model="sale.notes" disabled="disabled" name="note" rows="3" class="form-control"></textarea>
      </b-form-group>
    </b-col>
  </b-row>
  <!-- ------ BOTON ------ -->
  <b-row class="receipt-footer d-flex justify-content-end mb-3" v-if="printTicket === 0">
    <DownloadDoc :pdfurl="pdfurl" :open="false" :filename="sale.client.name+sale.created_at+'.pdf'">
      <b-button variant="outline-primary" size="lg" class="mr-4">Descargar</b-button>
    </DownloadDoc>
    <DownloadDoc :pdfurl="pdfurl" :open="true" :filename="sale.client.name+sale.created_at+'.pdf'">
      <b-button variant="orange" size="lg">Imprimir</b-button>
    </DownloadDoc>
  </b-row>
  <b-row class="receipt-footer d-flex justify-content-end mb-3" v-else>
    <b-button variant="orange" size="lg" @click="print">Imprimir</b-button>
  </b-row>

  
</b-container>
</template>

<script>
import DownloadDoc from "../../global/DownloadDoc";
import formatMoneyMixin from "../../../mixins/formatMoneyMixin";
import { mapState } from 'vuex'

export default {
  components: {
    DownloadDoc
  },
  mixins: [formatMoneyMixin],
  props: ["saleId"],
  data: function() {
    return {
      totalca:'',
      createdDateFormatted: null,
      pdfurl: process.env.VUE_APP_BASE_URL+'/api/sales/getURL/' + this.saleId,
      sale: {
        letter: null,
        pos: null,
        number: null,
        client_id: null,
        subtotal: null,
        discount: null,
        taxes: null,
        total: null,
        sale_details: [{
          sale_id: null,
          description: null,
          quantity: null,
          price: null
        }],
        client: {
          name: null,
          clerk: {
            name: null
          }
        }
      },
      printTicket: null,
    }
  },
  computed: {
    createdDateFormatted() {
      return new Date(this.sale.created_at).toLocaleDateString('es-AR')
    },
    serviceFromDateFormatted() {
      return new Date(this.sale.service_fromdate).toLocaleDateString('es-AR')
    },
    serviceToDateFormatted() {
      return new Date(this.sale.service_todate).toLocaleDateString('es-AR')
    },
    serviceExpDateFormatted() {
      return new Date(this.sale.service_expdate).toLocaleDateString('es-AR')
    }
  },
  mounted() {
    this.getSaleDetail(this.saleId);
    this.getBranchofficesOptions();
  },
  methods: {
    getSaleDetail(saleId){
      var vm= this;
      this.$http.get('/api/sales/getSale/' + saleId)
      .then(response => {
          if(response.data.success) {
              vm.sale = response.data.data;                
            }
          }
      )
      .catch(error => {
          alert(error);
          }
      )
    },
    padLeft(number, length) {
      return number.toString().padStart(length, '0');
    },
    getBranchofficesOptions(){
      this.$http.get('/api/configs/branchoffice/getData')
      .then(response => {
        let branchofficeSale = this.sale.bo_id;
        console.log(branchofficeSale);
        let branchoffices = response.data.data.branchoffices;
        console.log(branchoffices);
        let branchoffice = branchoffices.find(bo => bo.id === branchofficeSale);
        if(branchoffice.options.print_bill_ticket === 1){
          this.printTicket = branchoffice.options.print_bill_ticket;
        }else{
          this.printTicket = 0;
        }
      })
    },
    print(){
      window.location.href = '/sales/ticket/'+this.saleId;
    }
   },
   computed: {
      ...mapState('authUser',{
         authUser: state => state.authUser,
      }),
   }
};
</script>